<template>
  <div class="taskh_bgimg">
    <header class="flex-align-between tackheader">
      <div class="tackavatar flex-layout">
        <div class="tackhead">
          <img :src="avatar =='null' ? defaultImg:avatar1">
        </div>
        <ul class="justify_class flex-align-evenly">
          <li v-for="(item, index) in tackclass" :key="index" class="flex-column-center">
            <img :src="item.imgs" @click="skip(item.href)" />
            <p @click="skip(item.href)">{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <div class="goback flex-align-between">
        <img :src="gobackimgUrl" @click="goback" />
        <span @click="goback()">返回</span>
      </div>
    </header>
    <div class="tackmain flex-align-center">
      <div class="tacksurface" v-if="tackctoggle">
        <vxe-toolbar class="vxebar">
          <template v-slot:buttons>
            <vxe-input v-model="filterName" type="search" placeholder="请输入课程名" clearable @search-click="getjoblist()"
              @clear="getjoblist()"></vxe-input>
          </template>
        </vxe-toolbar>
        <vxe-table show-overflow class="mytable-style" align="center" :data="tableData">
          <vxe-table-column height="91" field="name" title="课程名称" type="html"></vxe-table-column>
          <vxe-table-column field="className" title="班级" type="html"></vxe-table-column>
          <vxe-table-column field="Submission" title="提交情况" type="html"></vxe-table-column>
          <vxe-table-column field="endTime" title="发布时间" type="html"></vxe-table-column>
          <vxe-table-column title="操作" type="html">
            <template v-slot="{ row }">
              <span type="text" icon="fa fa-edit" @click="insertEvent(row)" class="examine">查看</span>
            </template>
          </vxe-table-column>
          <template v-slot:empty>
            <span style="color: #21bd99">
              <p>没有更多数据了！</p>
            </span>
          </template>
        </vxe-table>
        <vxe-pager class="vxe_pager" :current-page.sync="page5.currentPage" :page-size.sync="page5.pageSize"
          :total="page5.totalResult" :layouts="[
            'PrevJump',
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'NextJump',
            'Sizes',
            'FullJump',
            'Total',
          ]" @page-change="getjoblist">
        </vxe-pager>
      </div>

      <div class="tacksurface tacksurface1 flex-align-between" v-else>
        <!-- flex-align-wrap -->
        <!-- flex-align-around -->
        <div class="triangle ">
          <img @click="slidePre" :src="triangle1" v-if="l_triangle_show" />
        </div>
        <div class="workbox">
          <slider ref="slider" :options="sliderinit" @slide="slide" @tap="onTap" @init="onInit" id="img_click">
            <slideritem v-for="(item, index) in someList" :key="index" :style="item.style" class="viewerbox">
              <ul>
                <!-- flex-align-wrap -->
                <li class="flex-align">
                  <label for="ratename">{{ item.userName }}</label>
                  <el-rate :value="1" disabled show-score text-color="#ff9900" :max="1"
                    :score-template="'x'+item.worksScore">
                  </el-rate>
                </li>
                <li class="img_popup flex-layout">
                  <viewer ref="imgs">
                    <img :src="item.compressionWorks" :data-source="item.works" />
                  </viewer>
                </li>
                <!-- flex-align-wrap -->
                <!-- flex-layout -->
                <li class="flex-column-between" v-if="item.worksSub == undefined?false:true">
                  <!-- flex-align-around -->
                  <!-- <span class="flex-layout" v-for="(items,index1s) in item.worksSub" :key="index1s">
                    <label for="ratename1">{{ value2[index1s] }}</label>
                    <el-rate :value="items*1" name="ratename1" :max="3" disabled text-color="#ff9900">
                    </el-rate>
                  </span> -->
                  <div class="flex-align-between">
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['right']}}</label>
                      <el-rate :value="item.worksSub['right']*1" name="ratename1" :max="3" disabled
                        text-color="#ff9900">
                      </el-rate>
                    </span>
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['tidy'] }}</label>
                      <el-rate :value="item.worksSub['tidy']*1" name="ratename1" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                  </div>
                  <div class="flex-align-between">
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['beauty']}}</label>
                      <el-rate :value="item.worksSub['beauty']*1" name="ratename1" :max="3" disabled
                        text-color="#ff9900">
                      </el-rate>
                    </span>
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['spec'] }}</label>
                      <el-rate :value="item.worksSub['spec']*1" name="ratename1" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                  </div>

                </li>
                <!-- flex-align-wrap -->
                <!-- flex-layout -->
                <!-- <li class="" v-if="item.worksSub == undefined?true:false">
                  flex-align-around
                  <span class="" v-for="(items,index2s) in 4" :key="index2s">
                    <label for="ratename2">{{ value1[index2s] }}</label>
                    <el-rate :value="0" name="ratename2" :max="3" disabled text-color="#ff9900">
                    </el-rate>
                  </span>
                </li> -->
                <li class="flex-column-between" v-if="item.worksSub == undefined?true:false">
                  <!-- flex-align-around -->
                  <!-- <span class="flex-layout" v-for="(items,index1s) in item.worksSub" :key="index1s">
                    <label for="ratename1">{{ value2[index1s] }}</label>
                    <el-rate :value="items*1" name="ratename1" :max="3" disabled text-color="#ff9900">
                    </el-rate>
                  </span> -->
                  <div class="flex-align-between">
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['right']}}</label>
                      <el-rate :value="0" name="ratename2" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['tidy'] }}</label>
                      <el-rate :value="0" name="ratename2" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                  </div>
                  <div class="flex-align-between">
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['beauty']}}</label>
                      <el-rate :value="0" name="ratename2" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                    <span class="flex-layout">
                      <label for="ratename1">{{ value2['spec'] }}</label>
                      <el-rate :value="0" name="ratename2" :max="3" disabled text-color="#ff9900">
                      </el-rate>
                    </span>
                  </div>

                </li>
              </ul>
            </slideritem>
            <div slot="loading">loading...</div>
          </slider>
        </div>
        <!-- flex-align-around -->
        <div class="triangle ">
          <img @click="slideNext" :src="triangle2" v-if="r_triangle_show" />
        </div>
      </div>
    </div>
    <subscribe :dialogVisible="centerDialogVisible2" @clear="clear" @sure="sure"></subscribe>
  </div>
</template>
<script>
  import moment from "moment";
  import {
    slider,
    slideritem
  } from "vue-concise-slider";
  import {
    mapState,
    mapMutations
  } from "vuex";
  import subscribe from "../../Common_components/Popup/subscribe";
  export default {
    data() {
      return {
        avatar: '',
        avatar1: '',
        defaultImg: require("@/assets/homepage/tou.png"),
        backgroundImage: "url(" + require("../../assets/homepage/bg.png") + ")",
        csimgUrl: require("../../assets/homepage/attendclassbg.png"),
        srcList: [
          require("../../assets/homepage/attendclassbg.png"),
          require("../../assets/homepage/attendclassbg.png"),
        ],
        gobackimgUrl: require("../../assets/task/return.png"),
        tackclass: [{
            name: "首页",
            href: "/home",
            imgs: require("../../assets/task/homepage.png"),
          },
          // {
          //   name: "备课",
          //   href: "/prepare",
          //   imgs: require("../../assets/task/lessonp.png"),
          // },
          {
            name: "上课",
            href: "/attend",
            imgs: require("../../assets/task/attendclass.png"),
          },
          {
            name: "作业",
            href: "/taskh",
            imgs: require("../../assets/task/task.png"),
          },
        ],
        tackctoggle: true,
        tableData: [],
        total_points: 0,
        value1: ['正确', '整洁', '美观', '规范'],
        value2: {
          'right': '正确',
          'tidy': '整洁',
          'beauty': '美观',
          'spec': '规范',
        },
        triangle1: require("../../assets/task/l_triangle.png"),
        triangle2: require("../../assets/task/r_triangle.png"),
        l_triangle_show: false,
        r_triangle_show: true,
        someList: [],
        filterName: "",
        page5: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
        sliderinit: {
          slidesToScroll: 3, //每次滑动项数
          freeze: true,
        },
        //Slider configuration [obj]
        options: {
          currentPage: 0,
        },
        centerDialogVisible2: false
      };
    },
    components: {
      slider,
      slideritem,
      subscribe
    },
    computed: {
      ...mapState({
        attend_id: (state) => state.home.attend_id,
        message: (state) => state.home.message,

      }),
    },
    mounted() {

      this.avatar = sessionStorage.getItem("avatar");

      this.avatar1 = 'https://www.lezhireading.com/uploads/' + sessionStorage.getItem("avatar").replace(/\"/g, "");;

      this.$nextTick(() => {
        this.getjoblist();
      });

    },
    methods: {
      ...mapMutations(["hint_data", 'message_data']),
      async getjoblist() {
        let _this = this;
        let data = {
          page: this.page5.currentPage,
          limit: this.page5.pageSize,
          key: this.filterName,
        };
        let resData = await this.$Api.Home.getHomeworkList(data);
        if (resData.data.list.length != 0) {
          this.tableData = resData.data.list;
          this.page5.totalResult = resData.data.totalCount;
          this.page5.pageSize = resData.data.pageSize;
          this.page5.currentPage = resData.data.currPage;
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(
              this.tableData[i],
              "Submission",
              this.tableData[i].finish + "/" + this.tableData[i].total
            );
            this.tableData[i].endTime = moment().format("YYYY年MM月DD日");
          }
        } else {
          this.tableData = [];
          this.page5.currentPage = 1;
          this.page5.pageSize = 10;
          this.page5.totalResult = 0;
        }
      },

      input_btn() {
        this.getjoblist();
      },
      skip(href) {
        if (href == "/attend") {
          this.$router.push({
            path: '/home',
          });
          this.message_data(true)
        } else if (href == "/taskh") {
          // this.$router.push(href);
          this.message_data(false)
        } else {
          this.$router.push(href);
          this.message_data(false)
        }

      },
      async insertEvent(row) {

        let data = {
          taskId: row.id,
        };
        let resData = await this.$Api.Home.getshow_works(data);
        this.someList = resData.data;
        // console.log(' this.someList', this.someList)
        for (let i = 0; i < this.someList.length; i++) {
          if (this.someList[i].worksSub != undefined) {
            this.someList[i].worksSub = JSON.parse(this.someList[i].worksSub)
            // this.someList[i].worksSub = eval("(" + this.someList[i].worksSub + ")")
          }
        }
        // console.log('作业', this.someList)
        if (this.someList.length == 0) {
          this.hint_data("该课程暂无提交作业");
          this.centerDialogVisible2 = true;
        } else {
          this.tackctoggle = false;
        }
      },
      goback() {

        if (this.tackctoggle == false) {
          this.tackctoggle = true;
        } else {
          this.$router.go(-1);
        }
      },
      slideNext() {
        this.$refs.slider.$emit("slideNext");
      },
      btn(e) {
        // console.log("img_click");
      },
      slidePre() {
        this.$refs.slider.$emit("slidePre");
      },
      slide(data, e) {
        if (data.currentPage == 0) {
          this.l_triangle_show = false;
          this.r_triangle_show = true;
        } else if (data.currentPage < this.someList.length - 3) {
          this.l_triangle_show = true;
          this.r_triangle_show = true;
        } else {
          this.l_triangle_show = true;
          this.r_triangle_show = false;
        }
      },
      onTap(data) {
        // console.log("onTap", data);
      },
      onInit(data) {
        // console.log("onInit", data);
      },
      clear(bool) {
        this.centerDialogVisible2 = bool;
      },
      sure(bool) {
        this.centerDialogVisible2 = bool;
      },
    },
  };
</script>
<style lang="less">
  @import "less/taskh.less";
</style>